.entry-list-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 20px;
  background-color: white;
  z-index: 1;
  text-align: center;
}

.entry-filter-title {
  font-weight: 500;
  font-size: 16pt;
  color: #767c87;
  margin-right: 5px;
  height: 46px;
  line-height: 46px;
}

.DateInput_input {
  color: #767c87;
  font-weight: 500;
}

.CalendarDay__selected_span {
  background: #6adcd9;
  color: white;
}

.entry-type-filter-container {
  padding: 0px;
}

.entries-list {
  max-width: 600px;
}

.no-entries {
  font-weight: 500;
  font-size: 20pt;
  color: #767c87;
}

@media (max-width: 576px) {
  .date-title,
  .type-title {
    visibility: hidden;
    width: 0px;
  }

  .entry-list-header {
    position: static;
  }

  .entry-date-filter-container {
    padding: 0px;
  }
}
