.entry-card {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 15px 0px rgba(51, 61, 77, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 200ms;
}

.entry-card-header {
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  background-color: white;
  padding: 10px 10px;
}

.entry-type {
  width: 48px;
  margin-left: 5px;
  margin-right: 5px;
}

.entry-type img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.entry-title {
  font-size: 16pt;
  font-weight: 600;
  color: #333d4d;
  line-height: 1.2em;
  text-align: left;
}

.entry-time {
  font-size: 13pt;
  font-weight: 500;
  color: #767c87;
  line-height: 1.2em;
  text-align: left;
}

.entry-card-description-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background: white;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.entry-text-only {
  padding: 10px 20px 20px;
  text-align: left;
  font-size: 18pt;
  font-weight: 500;
  color: #333d4d;
}

.entry-card-description {
  color: #333d4d;
  font-weight: 500;
  text-align: left;
}

.entry-image {
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
}

.entry-calories-container {
  display: inline-block;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 80%); /* Light grey background */
  color: #333d4d;
  border-radius: 8px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  left: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Ensure compatibility with Safari */
}

.tags-container {
  position: absolute;
  display: flex;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding-top: 10px;
  background-color: transparent;
  font-weight: 600;
  gap: 10px;
  flex-wrap: wrap;
}

.tag {
  display: inline-block;
  background-color: rgba(255, 255, 255, 80%); /* Light grey background */
  color: #333d4d; /* Dark text color */
  border-radius: 8px;
  padding: 5px 10px;
  backdrop-filter: blur(10px); /* Add blur effect */
  -webkit-backdrop-filter: blur(10px); /* Ensure compatibility with Safari */
}

.indicators {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 11pt;
  font-weight: 600;
  color: #333d4d; /* Dark text color */
}

.indicator {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.progress-bar {
  background-color: #e0e0e0; /* Light grey background for progress bar */
  overflow: hidden;
  height: 10px; /* Adjust height as needed */
  padding: 1px;
  width: 50px;
  border-radius: 5px;
}

.progress {
  height: 100%;
  background-color: white; /* Dark green color for progress */
  border-radius: 5px;
}

@media (max-width: 576px) {
  .entry-title {
    font-size: 14pt;
  }

  .entry-time {
    font-size: 12pt;
  }
}
